// body {
//   overflow: hidden; /* Hide scrollbars */
// }

.worksheetcontainer {
  overflow: hidden;
}

.left {
  // margin-top: 5% !important;
  margin-left: 1%;
}

.Left-head {
  margin-left: 2%;
}

.Left-head > h6 {
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  // padding: 20px;
  font-family: sans-serif;
}

.inner > h4 {
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  padding: 20px;
  font-family: sans-serif;
  margin-top: 5px;
}

.inner > h6 {
  font-weight: 100 !important;
  margin-bottom: 10px !important;
  // padding: 20px;
  font-family: sans-serif;
}

heading-5 {
  margin-left: 20px;
}

.left-col {
  height: 50%;
  width: 80%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  font-family: Rubik;
  background: white;
}

.row {
  flex-wrap: unset;
}

.inside-col {
  height: 10%;
  border: 2px solid gray;
  padding: 2%;
  margin: 2%;
  font-family: Rubik;
}

.heading-col {
  width: 100%;
  height: 6%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  margin-bottom: 10%;
  text-align: center;
  background: white;
  padding: 15px;
  // font-family: sans-serif;
}

.heading-4 {
  color: #9351cd;
}

.right-col {
  width: 82%;
  height: 96%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  // padding: 20px;
  margin: 5%;
  font-family: Rubik;
  overflow-y: auto;
  overflow-x: hidden;
  background: whitesmoke;
}

.right-inside-col {
  cursor: pointer;
  width: 90%;
  height: 13%;
  // border: 1px solid lightgray;
  border-radius: 8px;
  margin: 5%;
  // border-bottom: 1px solid rgba(129, 137, 156, 0.2);

  background: white;
  // padding: 20px 20px 20px 0px;
  // margin: 20px;
  font-family: Rubik;
}

.worksheet-button {
  width: 90%;
  margin-bottom: 10%;
}

// .right-inside-col {
//   .hr-line {
//     content: "";
//     position: absolute;
//     // bottom: 0;
//     // left: 0;
//     // right: 0;
//     z-index: 2;
//     width: 78%;
//     border-bottom: 1px solid rgba(129, 137, 156, 0.2);
//   }
// }

.event-btn1 {
  text-transform: uppercase;
  background-color: rgba(251, 131, 0, 0.16);
  color: #f4712b;
  font-size: 10px;
  margin-bottom: 10px;
  padding: 3px 8px;
  font-weight: 500;
  // display: block;
  border-radius: 4px;
  border: none;
  outline: none;
  margin-left: 37%;
  margin-top: 2%;
}

.inner {
  padding: 5px 0px 10px 30px;
  margin-top: 2%;
}

.back-btn {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  opacity: 0.8;
  margin-top: 95px;
  margin-left: 43px;
}

.selectedWorksheet {
  border: 1px solid #9351cd;
}
