.profile-progress-bar {
  position: relative;
  margin-top: 20px;
  .progress-bar-bg {
    width: 100%;
    height: 7px;
    background-color: #c9b4e4;
    border-radius: 100px;
  }
  .progress-bar-fill {
    width: 10%;
    height: 7px;
    background: #7927c2;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    border-radius: 100px;
  }
}
