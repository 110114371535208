.component-quiz-banner {
  // padding: 20px 40px;
  color: #fff;
  border-radius: 4px;
  &.banner-image {
    background: url("../../assets/image/quiz-banner.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 768px) {
      background: url("../../assets/image/quiz-banner-small.png") !important;
      background-repeat: no-repeat, no-repeat !important;
      background-size: 100% 100% !important;
    }
  }

  .text-container {
    position: relative;
    .description {
      display: none;
      @media screen and (max-width: 768px) {
        display: inherit;
        font-size: 12px;
      }
    }
    .name {
      display: none;
      @media screen and (max-width: 768px) {
        // transform: rotate(-3deg);
        display: initial;
        font-size: 20px;
        max-width: 700px;
        font-weight: bold;
        // font-family: COCOGOOSELETTERPRESS;
        margin-bottom: 12px;
        line-height: 1.3;
      }
    }
    .banner-btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}
