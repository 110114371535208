.component-quiz {
  padding: 25px;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #005aaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #005aaa;
    border-radius: 10px;
  }
  .ques-stepper {
    width: 30px;
    height: 5px;
    opacity: 0.2;
    border-radius: 2px;
    background-color: #ae9db6;
    margin-right: 4px;
  }
  .ques-stepper {
    &.active {
      background-color: #ff8923;
      opacity: 1;
    }
  }
  .question-card-container {
    margin-top: 24px;
    margin-bottom: 24px;
    .question-card {
      background: #fff;
      word-break: break-all;
      border-radius: 4px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
      font-size: 20px;
      font-weight: 500;
      color: #212121;
      padding: 24px 20px;
      // margin-top: 24px;
      // margin-bottom: 24px;
      z-index: 1000;
    }
    .question-card-dummy {
      border-radius: 4px;
      // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
      background-color: rgba(255, 255, 255, 0.7);
      width: 95%;
      height: 20px;
      z-index: 1;
      margin: auto;
      margin-top: -13px;
    }
  }
  .quiz-option {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .ques-image {
    width: 150px;
    height: 100px;
    border-radius: 4px;
  }
  .option-space {
    margin: 10px 10px 16px 10px;
    background: transparent;
    width: max-content;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
  }
  .quiz-option-container {
    display: flex;
    align-items: center;
  }
  .bg-correct {
    background-image: linear-gradient(97deg, #5ad98a, #50c399);
    color: #fff;
    font-weight: bold;
  }
  .bg-incorrect {
    background-image: linear-gradient(263deg, #d34242, #db5a5a);
    color: #fff;
    font-weight: bold;
  }
  .bg-selected {
    background-image: linear-gradient(263deg, #005aaa, #91D8F7);
    color: #fff;
    font-weight: bold;
  }
  .next-btn {
    font-weight: 600;
    margin-top: 12px;
    cursor: pointer;
    padding: 12px 0;
    user-select: none;
    font-size: 16px;
    float: right;
    color: #005aaa;
  }
  .card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: palegoldenrod;
    border-radius: 10px;
  }
}
