.tabList {
  &.tab-container-list {
    display: block;
    //height: 85vh;

    .tab-content {
      //box-shadow: 1px 1px 1px 0px rgba(44, 11, 74, .1) !important;
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      padding: 5px 0;

      .tab-1,
      .tab-2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .blur-container {
          position: absolute;
          background-color: #d7d7d7;
          padding: 20px 0;
          width: 95.5%;
          @media screen and (max-width: 990px) {
            width: 92.5% !important;
          }
          @media screen and (max-width: 1300px) {
            width: 94.5%;
          }

          bottom: 0;
          cursor: pointer;
        }

        .view-all {
          position: absolute;
          color: #524e59;
          bottom: 8px;
          font-weight: 500;
          font-size: 17px;
          text-align: center;
          left: 38%;
          cursor: pointer;
        }
      }
    }

    ul {
      border-bottom: none !important;
      background-color: #ececec;
      width: 100%;

      li:first-of-type {
        border-right: 1px solid rgba(44, 11, 74, 0.15);
        border-top-right-radius: 10px;
      }

      li {
        flex: 1;

        a {
          background-color: #f6f6f6;
          border-radius: 10px 10px 0 0;
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.25) !important;
          background-image: none !important;
          background-repeat: no-repeat;
          background-position: 11px 14px;
          background-size: 13px;
          padding: 10px 10px 10px 10px;
          @media screen and (max-width: 460px) {
            font-size: 13px;
            background-position: 8px 12.9px;
            background-size: 12px;
            padding: 14px 10px 14px 31px;
            line-height: 1;
          }

          &:hover {
            background-color: #fff;
            color: #000 !important;
            border-color: transparent !important;
          }

          &.active {
            background-color: #fff !important;
            color: #000 !important;
            border-color: transparent !important;
          }
        }
      }

      li:nth-of-type(2) {
        a {
          background-image: none !important;
        }
      }
    }

    .sticky {
      @media screen and (min-width: 460px) {
        position: sticky !important;
        z-index: 9999 !important;
        top: 0.1px;
      }
    }
  }
}
.announce_foot {
  background-color: #d7d7d7 !important;

  padding: 10px;
  margin-top: 35px;
  @media screen and (min-width: 460px) {
    margin-top: 5px;
  }
}
