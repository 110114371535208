.head-teacher {
  height: auto;
  margin-top: 8px !important;
  @media screen and (max-width: 460px) {
    height: unset;
    margin: 1rem 0 !important;
  }

  .teacher-section {
    padding: 1.5rem 15px !important;

    .headTecherIcon {
      width: 16px;
      vertical-align: revert;
      margin-right: 8px;
    }


    .friendMember {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 11px;

      img {
        &.friendPic {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          border: 3px solid white;
          object-fit: cover;
          background: white;
          margin-left: -15px;
        }
      }

    }
  }


}
