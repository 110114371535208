@import "assets/stylesheet/variables.scss";
.btn_last {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
}
.ml-16 {
  margin-left: 16px;
}
.component-summary {
  display: flex;
  height: 100%;
  overflow: auto;

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .bookmark-icon {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.11);
  }
  .close-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 16px;
    cursor: pointer;
    z-index: 1;
  }
  .left {
    flex: 0.4;
    position: relative;
    .story-card {
      position: absolute;
      bottom: 0px;
      padding-left: 16px;
      padding-bottom: 15px;
      color: #fff;
      // For shadow
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.76) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      padding: 19px;
      padding-top: 40px;
      .name {
        font-size: 26px;
        font-weight: 500;
      }
      // .author-name {

      // }
    }
    .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
      border-radius: 6px;
    }
    .left-header {
      padding-left: 30px;
      padding-top: 15px;
      text-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
      font-family: Rubik;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.23;
      color: #fff;
      position: absolute;
    }
    .mobile-back {
      display: none;
    }
    .mobile-bookmark {
      display: none;
    }
  }
  .description-card {
    flex: 0.6;
    width: 100%;
    border-radius: 6px;
    background-color: #f1f1f5;

    .summary-card {
      padding-left: 16px;
      padding-right: 20px;
      .summary {
        width: 100%;
        max-height: 360px;
        overflow: auto;
        font-family: Rubik;
        font-size: 16px;
        line-height: 1.5;
        color: #000;
        margin-bottom: 15px;
      }
      .title {
        margin-bottom: 7px;
        font-family: Rubik;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.86;
        letter-spacing: -0.3px;
        color: #1e1e1e;
      }
    }
    .audioBook {
      display: flex;
      padding-left: 15px;
      .text {
        font-family: Rubik;
        font-size: 14px;
        line-height: 1.71;
        color: #000;
      }
    }
    .read-time {
      // position: absolute;
      border-radius: 14px;
      // background-color: #ffffff;
      color: black;
      // right: 14px;
      // top: 10px;
      font-size: 12px;
      padding: 2.5px 6px 2px 15px;
      display: flex;
      // width: 52px;
      height: 20px;
    }
  }
  .division {
    width: 4%;
    background-color: transparent;
    height: 100%;
    flex: 0.01;
  }
  @media (max-width: 1023px) {
    .btn_last {
      padding: 5px;
      display: flex;
      justify-content: space-between;
    }
    .ml-16 {
      margin-left: 6px;
    }
    .ant-btn-lg {
      padding: 5px;
      //FREAD-1010 Rework
      //font-style: 12px;
    }
    //flex-direction: column;
    .quiz-active {
      .left {
        flex: 0.25;
      }
    }
    .left {
      height: 30%;
      //height: unset;
      flex: 0.5;
      .mobile-back {
        color: #fff;
        top: 16px;
        position: absolute;
        left: 5px;
        font-size: 16px;
        font-weight: 500;
        display: block;
      }
      .mobile-bookmark {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        margin-right: -15px;
      }
      .bg-image {
        object-position: top;
      }
      .story-card {
        .name {
          font-size: 18px;
          font-weight: bolder;
        }
      }
    }
    .bookmark-icon {
      display: none;
    }
    .description-card {
      flex: 0.5;
    }
  }
}
.ant-modal-header {
  display: none;
}
.ant-modal-content {
  background-color: transparent;
}
.ant-modal-close {
  display: none;
}

.media-container {
  width: 100vw;
  height: 100vh;
  background: #fff;
  .ant-modal-content {
    height: 100vh;
  }
}
