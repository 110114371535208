@import "assets/stylesheet/variables.scss";

.parent-form-fields {
  .close-btn {
    background: rgba(240, 239, 241, 0.9);
    font-weight: bold;
    border: none;
    border-radius: 100px;
    width: 40px;
    height: 40px;
  }
  .close-btn {
    &:hover {
      color: initial;
    }
  }
  .flat-btn {
    background-color: $magenta-color;
    font-weight: 500;
    border: none;
    font-size: 18px;
    outline: none;
    // margin-top: 10px;
    padding: 12px;
    // width: 32%
    height: 100%;
    border-radius: 10px;

    color: white;
  }
  .mobile-border {
    padding: 0px;
    border-radius: 20px;
    padding-top: 0px;
    margin-top: 20px;
    border: none;
    @media (max-width: $max-resp-width) {
      border: 1px solid #d9d9da;
      padding: 20px;
    }
  }
}
