.announcements {
  height: 100%;
  margin-top: 16px !important;
  @media screen and (max-width: 460px) {
    height: auto;
    overflow-y: unset;
    overflow-x: unset;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .gray-btn {
    margin: 8px 6px !important;
  }
  .updateHeader {
    padding: 10px 15px 0 15px !important;
    @media screen and (max-width: 460px) {
      padding: 10px 10px 0 10px !important;
    }
  }
  .announcement-mobile-view {
    background: white;
    //padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
