@import "assets/stylesheet/variables.scss";

.ant-col-10 {
  margin-top: 48px !important;
}

.school-form-fields {
  .flat-btn {
    border-radius: 16px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.89px;
    text-align: center;
    width: 160px;
    height: 50px;
    margin-right: 20px;
    margin-top: 20px;
    color: white !important;

    &.add-another-btn {
      margin-top: 76px;
      @media (max-width: $max-resp-width) {
        margin-top: 50px;
      }
    }
    &.remove-btn {
      margin-top: 50px;
      display: flex;
      @media (max-width: $max-resp-width) {
        display: none;
      }
    }

    &.remove-btn-mobile {
      display: none;
      margin-top: 5px;
      @media (max-width: $max-resp-width) {
        display: inline-block;
      }
    }
  }
}
