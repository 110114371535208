@import "assets/stylesheet/variables.scss";

.student-profile-form {
  padding-top: 100px;
  @media (max-width: $max-resp-width) {
    padding-top: 66px !important;
  }
  min-height: 100vh;
  background-color: #f6f6f6;
}
//FREAD-1276
.zero-padding {
  padding-top: 0px !important;
}
//FREAD-1276
