@import "assets/stylesheet/variables.scss";

.children-modal {
  font-family: "Rubik";
  height: 90%;
  overflow-y: hidden;
  margin: 10px 0;
  @media screen and (max-width: 460px) {
    overflow-y: hidden;
    height: 100%;
    /*::-webkit-scrollbar {
      display: none;
    }*/
  }

  .ant-modal {
    top: 160px;
    @media screen and (max-width: 460px) {
      width: 100% !important;
      max-width: unset;
      top: 100px;
    }
  }

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    //align-items: center;

    &.reverse {
      flex-direction: column;
    }
  }

  .listing-row {
    &-profile-pic {
      height: 60px;
      width: 60px;
      border-radius: 30px;
      background: #979797;
      display: inline-block;
      margin: 0;
      overflow: hidden;
      vertical-align: middle;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      img {
        height: 100%;
      }
    }
  }

  .j-c-c {
    justify-content: center;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .j-c-se {
    justify-content: space-evenly;
  }

  .j-c-fs {
    justify-content: flex-start;
  }

  .a-i-fs {
    align-items: flex-start;
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 25px 15px;
    border: none;
    @media screen and (max-width: 460px) {
      border-bottom: 1px solid #828a9d;
    }

    .ant-modal-title {
      color: #524e59;
      font-size: 28px;
      @media screen and (max-width: 460px) {
        font-size: 24px;
      }
    }
  }

  .ant-modal-content {
    background-color: white !important;
    color: #7d7a83;
    padding-bottom: 7px;
    overflow-y: auto;
    height: 62vh;
    @media screen and (max-width: 460px) {
      overflow-y: auto;
      height: 85vh;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

    .ant-modal-body {
      padding: 0px 10px 18px 10px !important;
      position: relative;
      height: 48vh;
      overflow: auto;
      @media screen and (max-width: 460px) {
        padding: 10px 0 25px 0 !important;
        height: 55vh;
      }

      .children-cards {
        flex-wrap: wrap;
        padding-bottom: 10px;

        .no-data-label {
          font-size: 18px;
          padding: 0 0 0 9px;
        }

        .children-card {
          flex-basis: 32.2%;
          padding: 15px 20px;
          border-radius: 8px;
          border: 1px solid rgba(151, 151, 151, 0.3);
          margin: 5px;

          @media screen and (max-width: 460px) {
            flex-basis: 100%;
            margin: 7.5px 15px;
            padding: 15px 25px;
          }

          .batchmate-content {
            @media screen and (max-width: 460px) {
              display: flex;
              flex-direction: row;
            }

            .profile {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .wow-pic {
                img {
                  width: 60px;
                  object-fit: contain;
                  height: 50px;
                  @media screen and (max-width: 460px) {
                    width: 50px;
                    height: 40px;
                  }
                }
              }
            }

            .batchmate-detail {
              display: flex;
              width: 100%;
              flex-direction: column;
              margin-top: 15px;
              @media screen and (max-width: 460px) {
                margin-left: 10px;
                margin-top: unset;
              }

              h2 {
                color: #524e59;
                font-size: 20px;
                margin-bottom: 12px;
              }

              .stats {
                display: flex;
                justify-content: space-between;

                .purple-text {
                  color: $magenta-color;
                  font-weight: 500;
                  font-size: 18px;
                  margin-bottom: 0;

                  ~ p {
                    font-weight: 400;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none;
  }

  .ant-modal-close {
    display: inline;

    .anticon {
      padding: 9px;
      background: #ececec;
      border-radius: 100px;
    }
  }

  .ant-modal-close-x {
    position: absolute;
    right: 2px;
    top: 5px;
    @media screen and (max-width: 460px) {
      right: 3px;
    }
  }
}
